/* Container styling */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f4f8;
  }
  
  /* Icon container styling with animation */
  .icon-container {
    background-color: #4caf50;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    animation: scaleBounce 2s infinite ease-in-out;
  }
  
  /* Keyframes for the bouncing scale effect */
  @keyframes scaleBounce {
    0%, 100% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
  }
  
  /* Checkmark styling */
  .checkmark {
    color: #fff;
    font-size: 50px;
    font-weight: bold;
  }
  
  /* Title animation */
  .title, .message, .order-details {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  /* Delays for each text element */
  .title {
    animation-delay: 0.5s;
  }
  
  .message {
    animation-delay: 1s;
  }
  
  .order-details {
    animation-delay: 1s;
  }

  .print-button {
    margin-top: 40px;
    padding: 10px 20px;
    background-color:#4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  /* Keyframes for fade-in effect */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  